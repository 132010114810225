<!-- DEPRECATED COMPONENT -->
<template>
  <div>
    <div class="button-files-section">
      <button
        class="files-button"
        variant="link"
        style="margin-top: -10px"
        v-b-modal.appointment-docs-modal
        :disabled="!docs.length"
      >
        Documentos
        <b-spinner v-if="loading"  class="loading" variant="primary" type="grow" />
      </button>
      <p class="examsLength">{{ docs.length }} Documentos</p>
    </div>
    <v-appointment-docs-modal :docs="docs" :typeDoc="typeDoc" />
  </div>
</template>
<script>
import AppointmentDocsModal from '../../components/General/AppointmentDocsModal.vue'
import { getDocTypes } from '@/utils/documentHelper.js'

export default {
  components: {
    'v-appointment-docs-modal': AppointmentDocsModal,
  },
  props: {
    attendanceId: String,
  },
  async mounted() {
    const channel = this.pusher.subscribe(`attendance-process-${this.clinic.id}`)
    channel.bind('finished', this.finished, this)
    await this.getAttendanceFiles();
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      loading: false,
      docs: [],
      typeDoc: getDocTypes()
    }
  },
  methods: {
    async getAttendanceFiles() {
      this.loading = true;
      const res = await this.api.getAttendanceFiles(this.attendanceId)
      this.docs = res.data
      this.loading = false;
    },
    async finished(data) {
      if (this.attendanceId === data.attendance_id) {
        await this.getAttendanceFiles();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.loading {
  width: 15px;
  height: 15px;
}
.button-files-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
  .files-button {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--blue-500);
    padding: 4px 16px;
    border: 2px solid #305bf2;
    border-radius: 8px;
  }

  .examsLength {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-placeholder);
  }
}


</style>
